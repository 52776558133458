import { Route, Switch } from 'react-router-dom'
import { isProduction } from '~/src/utils/env'
import { UserQualification } from '~/src/routes/UserQualification'
import { ExploreProduct, ExploreProductUngated } from '~/src/routes/ExploreProduct'
import { ExploreTravel } from '~/src/routes/ExploreTravel'
import { ExploreVirtualCards } from '~/src/routes/ExploreVirtualCards'
import { useRouteHistory } from './hooks/useRouteHistory'
import { VerifyUserEmailPage } from './routes/VerifyUserEmailPage'
import { UserSignUpPage } from './routes/SignUp'
import { DevIndex } from './routes/DevIndex'
import BookADemo from './routes/BookADemo'
import { ExploreAccountingAutomation } from './routes/ExploreAccountingAutomation'
import { ExploreIntelligence } from './routes/ExploreIntelligence'
import ContactSales from '~/src/routes/ContactSales'
import BookWithChilipiper from './routes/BookWithChilipiper'

function App() {
  useRouteHistory()
  return (
    <Switch>
      {!isProduction && <Route exact={true} path='/' component={DevIndex} />}
      <Route path='/qualification' component={UserQualification} />
      <Route path='/explore-product' component={ExploreProduct} />
      <Route path='/explore-product-xp' component={ExploreProductUngated} />
      <Route path='/explore-accounting-automation-gate' component={ExploreAccountingAutomation} />
      <Route path='/explore-travel' component={ExploreTravel} />
      <Route path='/explore-virtual-cards' component={ExploreVirtualCards} />
      <Route path='/explore-intelligence' component={ExploreIntelligence} />
      {/* Routes for lead-based sales redirecting  */}
      <Route path='/see-a-demo' component={BookADemo} />
      {/* TODO: Need to be added to cloudflare worker */}
      <Route path='/verify-email' component={VerifyUserEmailPage} />
      <Route path='/sign-up' component={UserSignUpPage} />

      <Route path='/contact-sales' component={ContactSales} />
      <Route path='/book-time' component={BookWithChilipiper} />
    </Switch>
  )
}

export default App
