import { useCallback } from 'react'
import { useUpdateLeadModel } from '~/src/models/leads'
import { trackEvent } from '~/src/utils/analytics'
import { ChilipiperEvent } from '~/src/utils/analytics/types'
import CookiesUtil from '~/src/utils/cookies'
import { logError } from '~/src/utils/errors'
import { BankBalance, useCreateLead } from '~/src/hooks/leads'
import { isProduction } from '~/src/utils/env'
import { debug } from '~/src/utils/debugLog'
import { useRyuToast } from '@ramp/ryu'
type ChilipiperLead = {
  FirstName: string
  LastName: string
  Email: string
}

type ParamLead = {
  first_name: string
  last_name: string
  email: string
  employee_count?: number
  monthly_spend?: number
  bank_balance?: BankBalance
}

type ChilipiperParams = {
  queue: string
  lead: ParamLead
  onCalendarShown: () => void | Promise<void>
  onSuccess: () => void | Promise<void>
  onClose: () => void | Promise<void>
  onError: (error: Error) => void
  ignoreNonProduction?: boolean
}

function launchChilipiper(params: ChilipiperParams, toast?: ReturnType<typeof useRyuToast>) {
  if (!isProduction && !params.ignoreNonProduction) {
    debug.log('Attempting to launch Chilipiper in non-production environment', params)
    return
  }

  if (!isProduction && params.ignoreNonProduction) {
    debug.log('Launching Chilipiper in non-production environment', params)

    if (toast) {
      toast({
        title: 'Launching Chilipiper in non-development environment',
        description: 'This is a production environment, please be not to book a meeting accidentally!',
        color: 'warning',
      })
    } else {
      // eslint-disable-next-line no-alert
      alert('Launching Chilipiper in non-production environment, please be careful!')
    }
  }

  debug.log('Launching Chilipiper', params)

  const { queue, lead, onCalendarShown, onSuccess, onClose, onError } = params
  const chilipiperLead: ChilipiperLead = {
    FirstName: lead.first_name,
    LastName: lead.last_name,
    Email: lead.email,
  }
  if (window.ChiliPiper) {
    window.ChiliPiper.submit('ramp-com', queue, {
      lead: chilipiperLead,
      onRouted: onCalendarShown,
      onSuccess,
      onClose,
      onError,
    })
  }
}

function trackChilipiperEvent<TQualificationEvent extends ChilipiperEvent>(
  event: TQualificationEvent[0],
  cookies: { _fbp: string; _fbc: string },
  { first_name, last_name, email }: ParamLead
) {
  debug.log('Tracking Chilipiper event', event, cookies, { first_name, last_name, email })
  const eventPayload: TQualificationEvent[1] = {
    fbp: cookies._fbp,
    fbc: cookies._fbc,
    first_name,
    last_name,
    company_email: email,
    discovery_method: 'contact_sales',
  }
  trackEvent(event, eventPayload)
}

type ChilipiperSubmissionConfig = {
  // Required params
  queue: string
  // Optional config
  shouldCreateCoreLead?: boolean
  // Launch Chilipiper in non-production environments
  ignoreNonProduction?: boolean
  // Callback functions
  onCalendarShown?: () => void | Promise<void>
  onSuccess?: () => void | Promise<void>
  onClose?: () => void | Promise<void>
  onError?: (error: Error) => void
  onExit?: () => void | Promise<void>
}

function useChilipiperSubmission(config: ChilipiperSubmissionConfig) {
  const {
    queue,
    shouldCreateCoreLead,
    ignoreNonProduction,
    onCalendarShown,
    onSuccess,
    onClose,
    onError,
    onExit,
  } = config
  const createLead = useCreateLead()
  const updateLead = useUpdateLeadModel()
  const toast = useRyuToast()
  const generateLead = useCallback(
    async (lead: ParamLead) => {
      const leadResponse = await createLead(lead.email, {
        discovery_method: 'contact_sales',
        first_name: lead.first_name,
        last_name: lead.last_name,
      })
      if (!leadResponse) {
        throw new Error('Lead creation failed')
      }

      const leadId = leadResponse.id

      return leadId
    },
    [createLead]
  )

  const showChilipiperCalendar = useCallback(
    async (lead: ParamLead) => {
      debug.log('Showing Chilipiper calendar', lead)
      try {
        // Only create a lead if we need to
        let leadId: string | undefined
        if (shouldCreateCoreLead) {
          debug.log('Creating core lead', lead)
          leadId = await generateLead(lead)
        } else {
          debug.log('Not creating core lead', lead)
          leadId = undefined
        }

        const cookies = CookiesUtil.getAll<{ _fbp: string; _fbc: string }>()

        launchChilipiper(
          {
            queue,
            lead,
            ignoreNonProduction,
            async onCalendarShown() {
              debug.log('Chilipiper onCalendarShown', lead)
              trackChilipiperEvent('Chilipiper Widget - Calendar Shown', cookies, lead)
              if (onCalendarShown) {
                await onCalendarShown()
              }
            },
            async onSuccess() {
              debug.log('Chilipiper onSuccess', lead)
              if (shouldCreateCoreLead) {
                await updateLead(leadId, {
                  requested_demo: true,
                  sales_demo_booked_at: new Date().toISOString(),
                })
              }

              trackChilipiperEvent('Chilipiper Widget - Meeting Successfully Booked', cookies, lead)
              if (onSuccess) {
                await onSuccess()
              }
            },
            async onClose() {
              debug.log('Chilipiper onClose', lead)
              trackChilipiperEvent(
                'Chilipiper Widget - User Exited Without Booking Meeting',
                cookies,
                lead
              )
              if (onClose) {
                await onClose()
              }
            },
            async onError(error: Error) {
              debug.log('Chilipiper onError', lead, error)
              if (onError) {
                onError(error)
              }
            },
          },
          toast
        )
      } catch (error: any) {
        debug.log('Chilipiper onError', lead, error)
        if (onError) {
          onError(error)
        }

        logError.high(error, {
          tags: { owner: 'bdougherty_ramp' },
        })
      } finally {
        if (onExit) {
          await onExit()
        }
      }
    },
    [
      shouldCreateCoreLead,
      queue,
      ignoreNonProduction,
      toast,
      generateLead,
      onCalendarShown,
      onSuccess,
      updateLead,
      onClose,
      onError,
      onExit,
    ]
  )

  return showChilipiperCalendar
}

export { useChilipiperSubmission }
