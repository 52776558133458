import { isDevelopment } from './env'

/**
 * A nice util for logging only in a development environment
 *
 * If it's not development, it'll just be a no-op
 *
 * @example
 *
 * debug.log('Hello, world!')
 * // If it's development, this will log to the console
 * // If it's not development, this will be a no-op
 */

const debug: Pick<Console, 'log' | 'error' | 'warn' | 'info' | 'debug' | 'trace'> = isDevelopment
  ? console
  : {
      log: () => {},
      error: () => {},
      warn: () => {},
      info: () => {},
      debug: () => {},
      trace: () => {},
    }

export { debug }
