import { RyuIcon, RyuText } from '@ramp/ryu'
import { Fragment, ReactNode } from 'react'
import { styled } from 'styled-components'

const IconItemList = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  margin-bottom: 8px;
`

const IconItem = styled(RyuIcon)`
  margin: 0 !important;
`

// It is used, linter is wrong
// eslint-disable-next-line @typescript-eslint/no-unused-vars
type CopyVariant = {
  title: ReactNode
  description: ReactNode
  createCoreLead: boolean
  queue: string
}

export const copyVariants = {
  'procurement-specialist': {
    createCoreLead: true,
    queue: 'p2p-concierge-queue',
    title: (
      <Fragment>
        Let’s get you connected
        <br />
        with a Procurement Specialist
      </Fragment>
    ),
    description: (
      <Fragment>
        <IconItemList>
          <IconItem type='help-circle' />
          <RyuText.div align='left' size='m' color='hushed'>
            Answers to any questions you have about Ramp
          </RyuText.div>
        </IconItemList>
        <IconItemList>
          <IconItem type='dollar-sign' />
          <RyuText.div align='left' size='m' color='hushed'>
            A custom assessment to see how much Ramp will save you
          </RyuText.div>
        </IconItemList>
        <IconItemList>
          <IconItem type='merge' />
          <RyuText.div align='left' size='m' color='hushed'>
            Learn how Ramp can help you streamline your procurement process
          </RyuText.div>
        </IconItemList>
      </Fragment>
    ),
  },
} as const satisfies Record<string, CopyVariant>
