import { Fragment, FunctionComponent } from 'react'
import { GoogleTagManager } from '~/src/scripts/gtm'
import { isProduction } from '~/src/utils/env'
import { Helmet } from 'react-helmet-async'
import { redirectToThanks } from '../BookADemo/redirectUtils'
import { BookWithChilipiperForm } from './BookWithChilipiperForm'
import { copyVariants } from './variants'

/**
 * Book a demo using query params to configure the Chilipiper widget & behavior
 *
 * Query params:
 *  - email: email address to prefill
 *  - variant: variant to use for the copy and options (see variants.tsx, default procurement-specialist)
 *  - queue: override the queue to use for the Chilipiper widget (otherwise defaults to the variant setting)
 *  - core_lead: override whether to create a core lead (otherwise defaults to the variant setting)
 */

const BookWithChilipiper: FunctionComponent = () => {
  const params = new URLSearchParams(window.location.search)
  const variant = params.get('variant') as keyof typeof copyVariants
  const email = params.get('email') ?? undefined
  const queueOverride = params.get('queue') ?? undefined
  const createCoreLeadOverride = params.get('core_lead') ?? undefined
  const overrides = {
    queue: queueOverride,
    createCoreLead: createCoreLeadOverride !== undefined ? createCoreLeadOverride === 'true' : undefined,
    ignoreNonProduction: params.get('force_chilipiper') === 'true',
  }

  return (
    <Fragment>
      {isProduction && <GoogleTagManager />}
      <Helmet>
        {/* Include chilipiper script */}
        <script src='https://js.chilipiper.com/marketing.js' type='text/javascript' />
      </Helmet>
      <BookWithChilipiperForm
        variant={variant}
        email={email}
        variantPropertyOverrides={overrides}
        ignoreNonProduction={overrides.ignoreNonProduction}
        onSubmit={redirectToThanks}
      />
    </Fragment>
  )
}

export default BookWithChilipiper
