import { Fragment, FunctionComponent, useCallback, useState } from 'react'

import { RyuBreak, RyuGrid, RyuText, RyuButton, RyuPad, RyuLoadingOverlay, useRyuToast } from '@ramp/ryu'

import { styled } from 'styled-components'
import rampLogo from '~/src/images/logos/logo-with-name.svg'
import { useForm } from 'react-hook-form'
import { KenForm } from '~/src/components/ken/KenForm/KenForm'
import { KenFieldText } from '~/src/components/ken/KenForm/KenFieldText'
import contactSalesAvatarStack from '~/src/images/contact-sales-avatar-stack.png'
import { ContactSalesFormValues } from './types'
import { useChilipiperSubmission } from '~/src/hooks/chilipiper/useChilipiperSubmission'
import { copyVariants } from './variants'
import { debug } from '~/src/utils/debugLog'

const Flex = styled.div`
  display: flex;
  flex-direction: column;
`

const MaxWidthCenteredContainer = styled.div`
  max-width: 680px;
  margin: 0 auto;
  padding: 24px 0;
`

const ContactSalesAvatarStack = styled.img`
  max-width: 10rem;
`

export type Props = {
  onSubmit: () => void
  email?: string
  variant?: keyof typeof copyVariants
  variantPropertyOverrides?: {
    queue?: string
    createCoreLead?: boolean
  }
  ignoreNonProduction?: boolean
}

const DEFAULT_VARIANT: keyof typeof copyVariants = 'procurement-specialist'

export const BookWithChilipiperForm: FunctionComponent<Props> = ({
  onSubmit,
  email,
  variant = DEFAULT_VARIANT,
  variantPropertyOverrides,
  ignoreNonProduction,
}) => {
  const {
    title,
    description,
    queue: variantQueue,
    createCoreLead: variantCreateCoreLead,
  } = copyVariants[variant] ?? copyVariants[DEFAULT_VARIANT]

  const { queue: queueOverride, createCoreLead: createCoreLeadOverride } = variantPropertyOverrides ?? {}
  const queue = queueOverride ?? variantQueue
  const shouldCreateCoreLead =
    createCoreLeadOverride !== undefined ? createCoreLeadOverride : variantCreateCoreLead

  const toast = useRyuToast()

  const initialValues = {
    first_name: '',
    last_name: '',
    company_name: '',
    email: email ?? '',
  }

  const [isUpdating, setIsUpdating] = useState(false)

  // Memoized to keep things from rerendering
  const chilipiperOnExit = useCallback(() => {
    debug.log('BookWithChilipiperForm onExit')
    setIsUpdating(false)
  }, [])

  const chilipiperOnSuccess = useCallback(() => {
    debug.log('BookWithChilipiperForm onSuccess')
    onSubmit()
  }, [onSubmit])

  const chilipiperOnError = useCallback(
    (error: Error) => {
      debug.log('BookWithChilipiperForm onError', error)
      setIsUpdating(false)
      toast({
        title: 'Error booking demo',
        description: error.message,
        color: 'destructive',
      })
    },
    [toast]
  )

  const showChilipiperCalendar = useChilipiperSubmission({
    queue,
    shouldCreateCoreLead,
    onExit: chilipiperOnExit,
    onError: chilipiperOnError,
    onSuccess: chilipiperOnSuccess,
    ignoreNonProduction,
  })

  const onFormSubmit = useCallback(
    async (data: ContactSalesFormValues) => {
      debug.log('BookWithChilipiperForm onFormSubmit', data)
      setIsUpdating(true)
      await showChilipiperCalendar(data)
    },
    [showChilipiperCalendar]
  )

  const form = useForm<ContactSalesFormValues>({ defaultValues: initialValues })

  return (
    <Fragment>
      {isUpdating && <RyuLoadingOverlay />}
      <MaxWidthCenteredContainer>
        <RyuPad sizeTop='xl' sizeBottom='xxl'>
          <img src={rampLogo} height={26} alt='Company Logo' />
          <div>
            <ContactSalesAvatarStack src={contactSalesAvatarStack} />
            <RyuText.h2 align='left' size='l' paddingVertical='top'>
              {title}
            </RyuText.h2>
            <RyuBreak size='s' />
            {description}
            <RyuBreak size='m' />
            <KenForm
              form={form}
              render={({ formProps }) => (
                <form {...formProps}>
                  <Flex>
                    <KenFieldText<ContactSalesFormValues>
                      name='email'
                      type='email'
                      label='Work email'
                      variant='outlined'
                      hasError={!!form.formState.errors.email}
                      rules={{ required: true }}
                      disabled={!!email}
                    />
                    <RyuBreak size='xs' />
                    <RyuGrid columns={2} responsiveColumns={1} gapSize='s'>
                      <KenFieldText<ContactSalesFormValues>
                        name='first_name'
                        label='First name'
                        variant='outlined'
                        hasError={!!form.formState.errors.first_name}
                        rules={{ required: true }}
                      />
                      <KenFieldText<ContactSalesFormValues>
                        name='last_name'
                        label='Last name'
                        variant='outlined'
                        hasError={!!form.formState.errors.last_name}
                        rules={{ required: true }}
                      />
                    </RyuGrid>

                    <RyuBreak size='xs' />

                    <KenFieldText<ContactSalesFormValues>
                      name='company_name'
                      label='Company name'
                      variant='outlined'
                      hasError={!!form.formState.errors.company_name}
                      rules={{ required: true }}
                    />

                    <RyuBreak size='l' />

                    <RyuButton
                      variant='prime'
                      _textAlign='center'
                      color='primary'
                      type='submit'
                      fullWidth={true}
                    >
                      Book a demo
                    </RyuButton>
                  </Flex>
                </form>
              )}
              onSubmit={onFormSubmit}
            />
          </div>
        </RyuPad>
      </MaxWidthCenteredContainer>
    </Fragment>
  )
}
